import React from 'react';
import { Link } from 'react-router-dom';

import THEME from 'state/theme';
import styles from './LogoAdmin.module.scss';

const LogoAdmin = ({ dark }) => (
  <figure
    className={[styles.logo, dark ? styles.dark : styles.light].join(' ')}
    style={{ borderColor: THEME.color }}
  >
    <Link className="navbar-brand" to="/admin">
      <img src="/logo-secolab.png" alt="" style={{ width: '150px' }} />{' '}
    </Link>
  </figure>
);

export default LogoAdmin;
