import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import {
  DisabledTableRow,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from '../style';
import { useTeacherTask } from '../../../hooks';
import { Loading } from '../../../components/ui';

const TeacherTask = () => {
  const { fetchTasks } = useTeacherTask();
  const { data: tasks, isLoading } = fetchTasks || {};

  return (
    <>
      <Header data={{ title: '과제 관리', contents: '수강전 선수과제' }} />
      <Navbar title={'과제 관리'} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>과제명</div>
            <div className="md-div">제출기간</div>
            <div className="sm-div">제출인원</div>
            <div className="sm-div">채점완료</div>
          </TableHeader>
          {isLoading && <Loading />}

          {tasks?.map((item, i) => (
            <Link key={item.id} to={`/teacher/task/${item.id}`}>
              <TableRow>
                <div className="sm-div">{i + 1}</div>
                <div>{item.title}</div>
                <div className="md-div">{`${item.startDate.split('T')[0]} ~ ${
                  item.endDate.split('T')[0]
                }`}</div>
                <div className="sm-div">{`${item.submitCount}`}</div>
                <div className="sm-div">{`${item.scoreCount}`}</div>
              </TableRow>
            </Link>
          ))}
          {!isLoading && tasks?.length === 0 && (
            <DisabledTableRow>
              <div>출제된 과제가 없습니다.</div>
            </DisabledTableRow>
          )}
        </Table>
      </Wrapper>
    </>
  );
};

export default withRouter(TeacherTask);
