import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ko } from 'date-fns/esm/locale';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import AuthContext from 'context/Auth.context';
import { useFetchClassDetail, useTaskBoard } from 'hooks';
import { confirmSwal, errorMessage, successMessage } from 'utilities';
import { ClassWrapper, StyledError, WriteContentBox } from './ClassBoardCommon';
import { TextEditor } from 'components/elements';
import ClassBoardWriteButton from 'components/elements/ClassBoardWriteButton';
import TaskBoardAppendixFile from './TaskBoardAppendixFile';

const TaskBoardWrite = () => {
  const { type, id } = useParams();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const TITLE_MAX_LENGTH = 70;
  const CONTENT_MAX_LENGTH = 5800;

  /* 과제 파일(단일) */
  const [file, setFile] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [month, setMonth] = useState(new Date().getMonth());
  const [deleteAttachmentId, setDeleteAttachmentId] = useState('');
  const [textValue, setTextValue] = useState('');

  const { mutateAsync } = useTaskBoard({
    type,
    id,
  });

  const { data: taskDetail, isLoading: isDetailLoading } = useFetchClassDetail({
    type,
    id,
  });
  const { data } = taskDetail || {};

  useEffect(() => {
    if (!taskDetail) return;

    setValue('title', data?.title);
    setTextValue(data?.content);
    setDateRange([new Date(data?.startDate), new Date(data?.endDate)]);
    setFile(
      data?.taskAttachment.originalFileName ? [data?.taskAttachment] : []
    );
  }, [id, isDetailLoading]);

  const submit = async (form) => {
    const confirm = await confirmSwal({
      title: `과제를 ${id ? '수정' : '등록'} 하시겠습니까?`,
      confirmButton: '확인',
    });

    if (confirm) {
      let parsedTextValue = textValue
        .replaceAll('<p>', '')
        .replaceAll('</p>', '');
      const hasOnlyBr = /^<br>(<br>)*$/.test(parsedTextValue);
      if (
        textValue.trim() === '' ||
        hasOnlyBr ||
        parsedTextValue.trim() === ''
      ) {
        errorMessage({ text: '본문 내용을 입력해주세요.' });
        return;
      }

      const frm = new FormData();

      frm.append('title', form.title);
      frm.append('content', textValue);
      frm.append('classId', auth.classId);
      frm.append('subClassId', auth.subClassId);
      if (!!file.length) {
        frm.append('file', file[0]);
      }
      if (deleteAttachmentId) {
        frm.append('deleteAttachmentId', deleteAttachmentId);
      }

      const timezoneOffset = new Date().getTimezoneOffset() * 60000;
      frm.append(
        'startDate',
        new Date(startDate - timezoneOffset).toISOString().split('T')[0]
      );
      frm.append(
        'endDate',
        new Date(endDate - timezoneOffset).toISOString().split('T')[0]
      );

      try {
        const response = await mutateAsync({ formData: frm });
        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          successMessage({ text: `과제가 ${id ? '수정' : '등록'}되었습니다.` });
          history.push(`/myclass/board/${type}`);
        }
      } catch (error) {
        errorMessage({
          text: `과제 ${id ? '수정' : '등록'}에 문제가 발생하였습니다.`,
        });
      }
    }
  };

  const handleChangeFile = (fileList) => {
    setFile([...fileList]);
  };

  const handleDeleteFile = (index, fileId) => {
    setFile([]);
    setDeleteAttachmentId(fileId);
  };

  const handleMonthChange = (date) => {
    setMonth(date.getMonth());
  };

  const getDayName = (date) => {
    return date.toLocaleDateString('ko-KR', { weekday: 'long' }).substr(0, 1);
  };

  const createDate = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  };

  useEffect(() => {
    if (textValue.length < CONTENT_MAX_LENGTH) return;

    if (textValue.length > CONTENT_MAX_LENGTH) {
      errorMessage({
        text: `5000자 이하로 입력해주세요. (현재: ${textValue.length}자)`,
      });
      setTextValue((prevTextValue) =>
        prevTextValue.slice(0, CONTENT_MAX_LENGTH - 50)
      );
    }
  }, [textValue.length]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <ClassWrapper>
        <h1>과제 {id ? '수정' : '작성'}</h1>

        <WriteContentBox>
          <div>
            <div className="input-box">
              <input
                {...register('title', {
                  required: true,
                  maxLength: TITLE_MAX_LENGTH,
                  pattern: /^[^/]*$/,
                })}
                type="text"
                placeholder="제목을 입력하세요."
              />
              {errors.title?.type === 'required' && (
                <StyledError>제목을 입력해주세요.</StyledError>
              )}
              {errors.title?.type === 'maxLength' && (
                <StyledError>
                  {TITLE_MAX_LENGTH}자 이하로 입력해주세요.
                </StyledError>
              )}
              {errors.title?.type === 'pattern' && (
                <StyledError>과제 제목에 '/'는 포함될 수 없습니다.</StyledError>
              )}
            </div>
          </div>

          <div>
            <div className="content-box">
              <TextEditor htmlStr={textValue} setHtmlStr={setTextValue} />
            </div>
          </div>

          <div className="date-range">
            <div className="content-box">
              <DatePickerWrapper
                locale={ko}
                placeholderText="제출기간을 입력해주세요."
                selectsRange={true}
                startDate={startDate}
                isClearable
                onMonthChange={handleMonthChange}
                onCalendarClose={() => setMonth(new Date().getMonth())}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                minDate={new Date()}
                withPortal
                dayClassName={(date) =>
                  date.getMonth() !== month
                    ? 'gray-date'
                    : getDayName(createDate(date)) === '토'
                    ? 'saturday'
                    : getDayName(createDate(date)) === '일'
                    ? 'sunday'
                    : undefined
                }
              />
            </div>
          </div>

          <div className="files">
            <div className="content-box">
              <TaskBoardAppendixFile
                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
                originalFileNames={file}
              />
            </div>
          </div>

          <ClassBoardWriteButton cancleUrl={`/myclass/board/${type}`} />
        </WriteContentBox>
      </ClassWrapper>
    </form>
  );
};

const DatePickerWrapper = styled(DatePicker)`
  width: 48%;
  font-size: 15px;
  border: 1px solid rgb(206, 206, 206);
  height: 45px;
  padding-left: 10px;

  ~ button {
    position: relative !important;
    margin-left: 20px;
    padding-bottom: 5px;
  }
`;

export default TaskBoardWrite;
