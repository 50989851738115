import { useQuery } from 'react-query';
import { fetchTasksByTeachers } from '../../apis';
import { handleErrorMessage } from '../../utilities';

const useTeacherTask = () => {
  const fetchTasks = useQuery({
    queryKey: ['fetchPblProblemDetail'],
    queryFn: async () => await fetchTasksByTeachers(),
    select: (res) => res.data,
    retry: 2,
    retryDelay: 500,
    onError: handleErrorMessage,
  });

  return {
    fetchTasks,
  };
};

export default useTeacherTask;
