import React, { useContext, useEffect, useState } from 'react';

import { useCustomState } from 'state/state';
import THEME from 'state/theme';
import AuthContext from 'context/Auth.context';
import { Button, Link, Logo } from '../../elements';
import { Backdrop } from '../../ui';
import styles from './Sidebar.module.scss';

const Sidebar = ({ data = [] }) => {
  const [state, actions] = useCustomState();
  const [submenu, setSubmenu] = useState(null);
  const auth = useContext(AuthContext);

  const hover = {
    color: THEME.color,
  };

  const toggleSubmenu = (index) => {
    index === submenu ? setSubmenu(null) : setSubmenu(index);
  };

  const toggleSidebar = () => {
    actions.toggleSidebar();
    state.backdrop && actions.toggleBackdrop();
  };

  const toggleModal = () => {
    actions.toggleSidebar();
  };

  const [menuData, setMenuData] = useState();

  useEffect(() => {
    const str = auth.type === '1' ? '교강사' : '스터디그룹';
    const str1 = auth.type === '1' ? '' : '역량 평가';
    let newArr = data.filter((item) => item.name !== str && item.name !== str1);
    if (auth.type !== '1') {
      setMenuData(
        newArr.map((item) =>
          item.name === '내클래스'
            ? {
                ...item,
                children: item.children.slice(0, 2),
              }
            : item
        )
      );
    } else {
      setMenuData(newArr);
    }
  }, [data, auth]);

  const menu = menuData?.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <Link click={toggleSidebar} url={item.url} hoverStyle={hover}>
            {item.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <Link url="#" hoverStyle={hover} click={() => toggleSubmenu(index)}>
              {item.name}
              <i
                className={
                  submenu === index ? 'las la-angle-up' : 'las la-angle-down'
                }
                onClick={() => toggleSubmenu(index)}
              />
            </Link>
          </span>
          <ul
            style={{
              height:
                submenu === index ? 30 * item.children.length + 'px' : '0',
            }}
          >
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <Link
                  click={toggleSidebar}
                  url={subitem.url}
                  hoverStyle={hover}
                >
                  {subitem.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  return (
    <div className={[styles.wrapper, state.sidebar && styles.show].join(' ')}>
      <div className={styles.sidebar}>
        <div className={styles.btn_close} onClick={toggleSidebar}>
          <Button
            after="&#xf00d;"
            type="solid-white-tb"
            hoverType="solid-gray-tb"
          />
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <ul className={styles.menu}>{menu}</ul>
        <div className={styles.btn_contacts}>
          <Button hoverType="solid-gray-tb">LOGIN</Button>
        </div>
      </div>
      <Backdrop />
    </div>
  );
};

export default Sidebar;
