import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AuthContext from 'context/Auth.context';
import RoadMapView from 'components/elements/RoadMap/RoadMap';
import DefaultPwdChange from '../../login/DefaultPwdChange';
import Board from '../board/Board';
import Practice from '../practice/Practice';
import Topbar from '../topbar/Topbar';
import Chapters from './chapters/Chapters';
import Detail from './Detail';
import styles from './units/unit.module.scss';
import PBL from '../pbl/PBL';
import { handleErrorMessage } from 'utilities';

const Room = () => {
  const match = useRouteMatch();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [unitList, setUnitList] = useState();
  const [classInfo, setClassInfo] = useState();
  const [subtitle, setSubtitle] = useState('');
  const [zoomUrl, setZoomUrl] = useState();
  const [unitTitle, setUnitTitle] = useState();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/myclass`;
    Axios.get(url)
      .then((response) => {
        setUnitList(response.data.data.unitDtoList);
        setClassInfo(response.data.data);
        setCurrentTime(new Date(response.data.timestamp));
        setSubtitle('단원 목록');
      })
      .catch(handleErrorMessage);
  }, []);

  const getTitle = (title) => {
    setUnitTitle(title);
  };

  useEffect(() => {
    getTitle();
  }, [location.pathname]);

  const handleZoom = (url) => {
    setZoomUrl(url);
  };

  useEffect(() => {
    console.log(
      `%cHELLO, ROOKIES!`,
      'font-weight: bold; font-size: 50px; text-shadow: 2px 2px 3px red'
    );
  }, []);

  return (
    auth.auth && (
      <>
        {!classInfo && <Box />}
        {classInfo && (
          <>
            <Topbar
              zoomUrl={zoomUrl}
              urlname="myclass"
              info={classInfo}
              unitTitle={unitTitle}
              currentTime={currentTime}
            />
            <div className={styles.contentBody}>
              <div className={styles.wrapper}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Switch>
                    <Route exact path={`${match.path}`}>
                      <Redirect to={`${match.path}/roadmap`} />
                    </Route>
                    <Route path={`${match.path}/board`} component={Board} />
                    {/* PBL */}
                    <Route path={`${match.path}/pbl`} component={PBL} />
                    <Route
                      path={`${match.path}/practice`}
                      component={Practice}
                    />
                    <Route
                      exact
                      path={`${match.url}/roadmap`}
                      render={() => <RoadMapView />}
                    />
                    <Route
                      exact
                      path={`${match.url}/classdetail`}
                      render={() => (
                        <Detail detail={classInfo.content} onClick={getTitle} />
                      )}
                    />

                    <Route
                      exact
                      path={`${match.url}/unit/:id`}
                      render={() => (
                        <Chapters
                          getTitle={getTitle}
                          getZoomUrl={handleZoom}
                          onClick={getTitle}
                        />
                      )}
                    />

                    <Route
                      exact
                      path={`${match.url}/unitdetail/:id`}
                      render={() => <Detail onClick={getTitle} />}
                    />
                  </Switch>
                </div>
              </div>
            </div>
            <DefaultPwdChange />
          </>
        )}
      </>
    )
  );
};

const Box = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export default Room;
